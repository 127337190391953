import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SiteNav from "../components/SiteNav/SiteNav"
import rightArrow from "../images/folders/Arrows/rn_121907_214113.gif"
import imgFolder from "../images/folders/Folders/rn_111303_114421.gif"
import imgSave from "../images/folders/Save/SmallSaveIcon.gif"
import imgLoading from "../images/folders/Loading/rn_090803_142913.gif"
import imgPunctuation from "../images/folders/Punctuation/rn_102504_084006.gif"
const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <div className="pure-g">
      <div className="pure-u-5-5 pure-u-md-1-5">
        <SiteNav />
      </div>
      <div className="pure-u-5-5 pure-u-md-4-5">
        <p>
          Now available - 41 collections containing over 5100 mini web-design
          icons! These mini icons are perfect for web applications, websites,
          and even for Microsoft Word documents. Here are just a few practical
          applications:
        </p>
        <p>
          Details: Most of the icons have a maximum height of 16 pixels and also
          have a transparent background for easy placement. 16x16 has proven the
          ideal size for a web application icon.
        </p>
        <blockquote>
          <img src={rightArrow} alt="Right Arrow" /> Point out a fact on a page
          <br />
          <img src={imgFolder} alt="Folder" /> Represent the action of storing a
          document
          <br />
          <img src={imgSave} alt="Save Floppy Disk" /> Prompt a user to save work
          <br />
          <img src={imgLoading} alt="Spinning Loading indicator" /> Mini Ajax loading indicator
          <br />
          <img src={imgPunctuation} alt="Alert Exclamation Point" /> Use as an alert indicator
        </blockquote>
        <p>
          Begin your search by choosing a category to the left. New icons are
          added regularly so check back often.
        </p>
      </div>
    </div>
  </Layout>
)

export default IndexPage
